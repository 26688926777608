export function isValidJsonArray(str) {
  try {
      const jsonArray = JSON.parse(str);

      return Array.isArray(jsonArray);
  } catch (e) {
      return false;
  }
}

export function transformJsonToString(objectJson, str = ',') {
  // 先變成Array
  const jsonArray = Object.values(objectJson);
  // 再分割變回String
  const separatedString = jsonArray.join(str);

  return separatedString;
}