<template>
  <div class="row">
    <div class="col-12">
      <b-overlay :show="showLoading">
        <b-card>
          <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
            <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
              優惠券使用紀錄
            </h4>
            <b-input-group
              class="col-12 col-xl-6 mb-2 d-flex justify-content-end"
            >
              <template v-if="upperCustomer.id">
                <b-button variant="primary" v-b-modal.modal-add>
                  手動給券
                </b-button>
              </template>
              <template v-if="showFilter">
                <b-select
                  class="mr-3"
                  :options="statusOptions"
                  v-model="status"
                  @change="fetchRedeemCodes"
                ></b-select>
                <b-form-input
                  v-model="keyword"
                  placeholder="搜尋會員名稱、手機、外部編碼"
                  v-on:keyup.enter="fetchRedeemCodes"
                ></b-form-input>
                <b-input-group-append>
                  <b-button @click="fetchRedeemCodes"
                    ><i class="fa fa-search"></i
                  ></b-button>
                </b-input-group-append>
              </template>
            </b-input-group>
          </div>

          <b-card-text>
            <div class="row">
              <div class="col-12">
                <b-overlay rounded="sm">
                  <b-table
                    striped
                    hover
                    responsive
                    :items="redeemCodes"
                    :fields="filteredFields"
                    @sort-changed="sortingChanged"
                  >
                  </b-table>
                </b-overlay>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
              ></b-pagination>
            </div>
          </b-card-text>
        </b-card>
      </b-overlay>
    </div>

    <b-modal id="modal-add" title="手動給券">
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="優惠券"
      >
        <vue-typeahead-bootstrap
          :data="filteredRedeem"
          v-model="redeemKeyWord"
          @hit="selectedRedeemCodeBundle = $event"
          :showOnFocus="true"
          :serializer="item => item.name"
          placeholder="請輸入獎項名稱"
        >
          <template slot="suggestion" slot-scope="{ data }">

              <div class="d-flex align-items-center" ><b-badge variant="outline-dark mr-2">獎項種類</b-badge>
                <span class="font-weight-bold">{{ data.name }}</span>
              </div>
              <div class="small py-2">
                {{ data.description }}
              </div>
              <div class="small font-weight-bold">
                <span>{{ data.start_at }}</span>
                <span class="mx-2">-</span>
                <span>{{ data.end_at }}</span>
              </div>

          </template>
        </vue-typeahead-bootstrap>
      </b-form-group>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
        <b-button
          size="sm"
          variant="outline-primary"
          @click="assignRedeemCodeByBundle"
        >
          確定
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import redeemCodeApi from "@/apis/redeem-code";
import redeemCodeBundleApi from "@/apis/redeemCodeBundle";
import { format } from "date-fns";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

export default {
  components: {
    VueTypeaheadBootstrap,
  },
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    excludeFields: {
      type: Array,
      default: () => [],
    },
    upperCustomer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showLoading: false,
      statusOptions: [
        { value: "above_belonged", text: "全部" },
        { value: "belonged", text: "未領取" },
        { value: "applied", text: "已領取" },
      ],
      status: "above_belonged",
      keyword: null,
      sortBy: "redeem_at",
      sortDesc: "desc",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      redeemCodes: [],
      canAssignRedeemCodesBundles: [],
      fields: [
        {
          key: "code",
          label: "序號",
        },
        {
          key: "customer",
          label: "會員",
          formatter: (value) => {
            if (value && value.name) {
              return this.hiddenString(value.name, 20);
            }
            return "";
          },
        },
        {
          key: "belong_at",
          label: "指派時間",
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
          sortable: true,
        },
        {
          key: "apply_at",
          label: "領取時間",
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
          sortable: true,
        },
        {
          key: "redeem_at",
          label: "使用時間",
          formatter: (value) => {
            return value
              ? format(new Date(value), "yyyy-MM-dd HH:mm:ss")
              : null;
          },
          sortable: true,
        },
        {
          key: "is_enabled",
          label: "啟用/停用",
          formatter: (value) => {
            return value ? "啟用" : "停用";
          },
        },
        {
          key: "status",
          label: "使用狀況",
          formatter: (value, key, item) => {
            switch (true) {
              case !!item.redeem_at:
                return "已兌換";
              case !!item.apply_at:
                return "已領取";
              case !!item.belong_at:
                return "已歸戶";
              default:
                return "未領取";
            }
          },
        },
      ],
      customers: [],
      selectedRedeemCodeBundle: null,
      redeemKeyWord: '',
    };
  },
  computed: {
    filteredFields() {
      return this.fields.filter(
        (field) => !this.excludeFields.includes(field.key)
      );
    },
    filteredRedeem() {
      return this.canAssignRedeemCodesBundles.filter(a => {
        return a.name.indexOf(this.redeemKeyWord) >=0
      })
    }
  },
  mounted() {
    this.fetchRedeemCodes();
    if (this.upperCustomer.merchant?.id) {
      this.fetchCanAssignRedeemCodes();
    }
  },
  watch: {
    currentPage() {
      this.fetchRedeemCodes();
    },
    upperCustomer(value) {
      if (value.id) {
        this.fetchCanAssignRedeemCodes();
      }
    },
  },
  methods: {
    async fetchRedeemCodes() {
      this.showLoading = true;
      let params = {
        status_for_admin: this.status,
        keyword_v2: this.keyword,
        per_page: this.perPage,
        page: this.currentPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc ? "desc" : "asc",
        // 若 upperCustomer.id 為 null，api 參數會被轉成 null 字串，所以這邊多一個判斷將 null 變成空字串
        customer_id: this.upperCustomer.id || "",
      };
      const response = await redeemCodeApi.getRedeemCodes(params);
      this.redeemCodes = response.data.data;
      this.totalRows = response.data.meta.total;
      this.showLoading = false;
    },
    async fetchCanAssignRedeemCodes() {
      this.showLoading = true;
      const response = await redeemCodeBundleApi.getCanAssignRedeemCodeBundles({
        merchant_id: this.upperCustomer.merchant.id,
      });
      this.canAssignRedeemCodesBundles = response.data.data
      this.canAssignRedeemCodesBundles.forEach((item) => {
        item.start_at = format(new Date(item.start_at), "yyyy-MM-dd");
        item.end_at = format(new Date(item.end_at), "yyyy-MM-dd");
      });

      this.showLoading = false;
    },
    sortingChanged(ctx) {
      console.log("ctx", ctx);
      this.currentPage = 1;
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.fetchRedeemCodes();
    },
    hiddenString(value, length) {
      value = value || "";
      const splittedValue = [...value];
      if (splittedValue.length <= length) {
        return value;
      }
      return splittedValue.slice(0, length).join("") + "...";
    },
    async assignRedeemCodeByBundle() {
      try {
        await redeemCodeBundleApi.assignRedeemCodeByBundle(
          this.selectedRedeemCodeBundle.id,
          { customer_id: this.upperCustomer.id }
        );
        this.$swal.fire({
          title: "新增成功",
          type: "success",
        });
      } catch (error) {
        this.$swal.fire({
          title: "新增失敗",
          type: "error",
        });
        console.log(error);
      } finally {
        this.$bvModal.hide("modal-add");
        this.fetchCanAssignRedeemCodes();
        this.selectedRedeemCodeBundle = null;
      }
    },
  },
};
</script>
